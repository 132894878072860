import React, { useRef } from "react";
import Releases from "./Releases";

const Blog = () => {
  const scrollToRef = useRef(null);

  return (
    <>
      <Releases scrollToRef={scrollToRef} />
    </>
  );
};

export default Blog;
