export const bids = [
  {
    title: "Gotong-royong bersama Nestle",
    price: "25 Point",
    highestBid: "Kuala Lumpur, MY",
    counter: "2 orang sertai",
    image: "/images/content/card-pic-2.jpg",
    image2x: "/images/content/card-pic-2@2x.jpg",
    category: "green",
    categoryText: "purchasing !",
    url: "/",
    users: [
      {
        avatar: "/images/content/avatar-1.jpg",
      },
      {
        avatar: "/images/content/avatar-3.jpg",
      },
    ],
  },
  {
    title: "Agihan Bubur lambuk",
    price: "12 Point",
    highestBid: "Kuala Lumpur, MY",
    counter: "3 orang sertai",
    bid: 'Popular <span role="img" aria-label="fire">🔥</span>',
    image: "/images/content/card-pic-3.jpg",
    image2x: "/images/content/card-pic-3@2x.jpg",
    category: "green",
    categoryText: "purchasing !",
    url: "/",
    users: [
      {
        avatar: "/images/content/avatar-1.jpg",
      },
      {
        avatar: "/images/content/avatar-2.jpg",
      },
      {
        avatar: "/images/content/avatar-4.jpg",
      },
    ],
  },
];
