import React from "react";
import cn from "classnames";
import styles from "./Video.module.sass";

const Video = () => {
  return (
    <>
      <div className={cn("section-bg", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.head}>
            <div className={styles.stage}>Daftar, Sertai, & Berkhidmat</div>
            <h3 className={cn("h3", styles.title)}>
              Cara terbaik untuk kehidupan yang lebih bermakna
            </h3>
          </div>
          <div className={styles.wrapper}>
            <div className={styles.row}>
              <iframe
                width="560"
                height="315"
                src="https://www.youtube.com/embed/SHC4UL1azW0"
                title="YouTube video player"
                frameborder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowfullscreen
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Video;
