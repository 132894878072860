import React from "react";
import cn from "classnames";
import styles from "./Description.module.sass";
import Image from "../../../components/Image";

const Description = () => {
  return (
    <div className={styles.section}>
      <div className={cn("container", styles.container)}>
        <div className={styles.wrap}>
          <div className={styles.stage}>
            Ayuh membantu mereka yang memerlukan
          </div>
          <h3 className={cn("h3", styles.title)}>
            Pertubuhan Dana Kebajikan Bertindak Altruis
          </h3>
          <div className={styles.text}>
            Organisasi untuk membudayakan sikap altruisme dalam kalangan
            masyarakat dan platform untuk membantu mereka yang memerlukan
          </div>
          {/* <div className={styles.btns}>
            <Link className={cn("button-stroke", styles.button)} to="/">
              Lebih lanjut
            </Link>
          </div> */}
        </div>
        <div className={styles.gallery}>
          <Image
            className={styles.pic}
            src="/images/content/hero.png"
            srcDark="/images/content/hero.png"
            alt="hero"
          />
        </div>
      </div>
    </div>
  );
};

export default Description;
