import React from "react";
import Video from "./Video";
import Description from "./Description";
import Objective from "./Objective";
import HotBid from "../../components/HotBid";
import Popular from "./Popular";

const Home = () => {
  return (
    <>
      <Description />
      <Video />
      <Objective />
      {/* <HotBid classSection="section" />
      <Popular /> */}
    </>
  );
};

export default Home;
