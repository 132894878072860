import React, { useState } from "react";
import { Link } from "react-router-dom";
import cn from "classnames";
import OutsideClickHandler from "react-outside-click-handler";
import styles from "./Language.module.sass";
import Image from "../../Image";

const items = [
  {
    title: "Malay",
    value: "my",
  },
];

const Language = ({ className }) => {
  const [visible, setVisible] = useState(false);

  return (
    <OutsideClickHandler onOutsideClick={() => setVisible(false)}>
      <div className={cn(styles.notification, className)}>
        <button
          className={cn(styles.head)}
          onClick={() => setVisible(!visible)}
        >
          <Image
            className={styles.pic}
            src="/images/content/translation.png"
            srcDark="/images/content/translation.png"
            alt="translation"
            width={32}
          />
        </button>
        {visible && (
          <div className={styles.body}>
            <div className={styles.list}>
              {items.map((x, index) => (
                <Link
                  className={styles.item}
                  to={x.url}
                  onClick={() => setVisible(!visible)}
                  key={index}
                >
                  <div className={styles.details}>
                    <div className={styles.subtitle}>{x.title}</div>
                  </div>
                </Link>
              ))}
            </div>
          </div>
        )}
      </div>
    </OutsideClickHandler>
  );
};

export default Language;
