import React from "react";
import cn from "classnames";
import styles from "./Main.module.sass";
import Breadcrumbs from "../../../components/Breadcrumbs";
import Share from "../../../components/Share";

const breadcrumbs = [
  {
    title: "Senarai Kiriman",
    url: "/blog",
  },
  {
    title: "Bil 001-06/10/2024",
    url: "/post",
  },
];

const Main = () => {
  return (
    <div className={cn("section", styles.main)}>
      <div className={cn("container", styles.container)}>
        <div className={styles.head}>
          <h2 className={cn("h2", styles.title)}>Berkhidmat untuk semua</h2>
          <Breadcrumbs className={styles.breadcrumbs} items={breadcrumbs} />
        </div>
        <div className={styles.content}>
          <p>
            Saya ingin mengambil kesempatan ini untuk merakamkan ucapan terima
            kasih atas kepercayaan yang diberi untuk saya menjawat sebagai
            Presiden Pertubuhan Dana Kebajikan Bertindak Altruis. Setakat ini,
            kita telah berjaya membentuk sebuah keluarga baru dengan ahli-ahli
            yang komited, dan kita berhasrat untuk melihat pertumbuhan yang
            lebih melalui penyertaan lebih ramai individu dalam perjuangan kita.
            <br />
            <br />
            Pertubuhan kita, yang dikenali sebagai Pertubuhan Dana Kebajikan
            Bertindak Altruis, berdiri dengan matlamat yang tulus - untuk
            menyemai semangat altruisme dalam kalbu setiap individu. Altruisme,
            yang merujuk kepada tindakan memberi tanpa mengharapkan balasan,
            adalah nilai luhur yang melibatkan tindakan ikhlas dan tulus yang
            patut dipraktik secara menyeluruh dalam konteks kehidupan masyarakat
            kita.
            <br />
            <br />
            Altruisme bukan sekadar usaha yang memberi manfaat kepada penerima,
            tetapi ia juga membawa kebahagiaan dan kesejahteraan kepada pemberi,
            memperkukuhkan hubungan sosial dalam masyarakat, menangani pelbagai
            cabaran sosial, dan membentuk sebuah komuniti yang bersatu padu dan
            berdaya mahir.
            <br />
            <br />
            Pertubuhan kita mempunyai matlamat yang jelas. Semua ahli perlu
            sentiasa bersikap tekun dan berintegriti dalam menjayakan mereka.
            Antara Matlamat yang telah dipersetujui bersama:
            <ol>
              <li>
                <strong>1. Kebajikan dan Sukarelawan:</strong> Mempertingkatkan
                kebajikan masyarakat dengan menyediakan bantuan dan sokongan
                kepada mereka yang memerlukan.{" "}
              </li>
              <li>
                <strong>
                  2. Pelatihan dan Pembangunan Kemahiran Keusahawanan Sosial:
                </strong>
                Membantu individu untuk memahami konsep keusahawanan sosial dan
                membangunkan kemahiran yang diperlukan.{" "}
              </li>
              <li>
                <strong>3. Pembentukan Generasi Muda yang Cekap:</strong>{" "}
                Melatih dan mempersiapkan generasi muda dengan kemahiran
                pengurusan dan teknologi yang baru.{" "}
              </li>
              <li>
                <strong>
                  4. Membina Jaringan Profesional dan Pakar Industri:
                </strong>{" "}
                Menghubungkan ahli pertubuhan dengan pakar industri dan
                profesional untuk meningkatkan ilmu dan kemahiran ahli.{" "}
              </li>
              <li>
                <strong>
                  5. Mencapai Matlamat Pembangunan Mampan PBB (SDG):
                </strong>{" "}
                Memainkan peranan secara aktif dalam mencapai Matlamat
                Pembangunan Mampan (SDG) PBB dengan usaha berterusan dan
                bersepadu.{" "}
              </li>
              <li>
                <strong>
                  6. Kerjasama dengan Pihak yang Berkongsi Matlamat yang Sama:
                </strong>
                Komited untuk menjalin kerjasama rapat dengan organisasi dan
                individu yang berkongsi matlamat yang sama untuk mencapai hasil
                yang lebih besar dan memberi impak yang lebih berkesan dalam
                masyarakat.{" "}
              </li>
            </ol>
            <br />
            Saya berharap agar perjuangan kita akan melahirkan lebih ramai
            individu yang mempunyai semangat altruisme dan proaktif dalam
            menjalankan tanggungjawab sosial terhadap masyarakat. Sekali lagi,
            kepada semua yang telah memulakan perjuangan ini, saya mengucapkan
            terima kasih. Mari kita bersama-sama berusaha untuk membina satu
            masyarakat yang lebih komited untuk lebih memberi daripada menerima
            di dunia yang kita kongsi ini.
            <br />
            <br />
            Sekian, Terima kasih.
            <br />
            <br />
            Mohammad Harith Bin Mohd Tarik
            <br /> Presiden Pertubuhan Dana Kebajikan Bertindak Altruis
          </p>
        </div>
        <div className={styles.actions}>
          <Share
            openUp
            content={{
              title: "Berkhidmat untuk Semua",
              url: "https://altruis.my/post",
            }}
          />
        </div>
      </div>
    </div>
  );
};

export default Main;
