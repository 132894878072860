import React from "react";
import cn from "classnames";
import styles from "./Releases.module.sass";
import Item from "./Item";

const items = [
  {
    title: "Berkhidmat untuk Semua",
    currency: "Perutusan Presiden",
    content: "Perkongsian ucaptama presiden kepada ahli-ahli pertubuhan",
    category: "red",
    categoryText: "New",
    image: "/images/content/releases-pic-1.jpg",
    image2x: "/images/content/releases-pic-1@2x.jpg",
    url: "/post",
  },
];

const Releases = ({ scrollToRef }) => {
  return (
    <div
      className={cn("section-bg section-mb0", styles.releases)}
      ref={scrollToRef}
    >
      <div className={cn("container", styles.container)}>
        <div className={styles.list}>
          {items.map((x, index) => (
            <Item className={styles.item} item={x} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Releases;
