import React, { useState } from "react";
import cn from "classnames";
import Slider from "react-slick";
import styles from "./Objective.module.sass";
import Icon from "../../../components/Icon";
import Modal from "../../../components/Modal";
import Connect from "../../../components/Connect";
import Image from "../../../components/Image";

const items = [
  {
    title: "Aktiviti kebajikan dan sukarelawan",
    desc: "Menjalankan aktiviti-aktiviti kebajikan dan sukarelawan untuk membantu golongan yang memerlukan bantuan",
  },
  {
    title: "Pelatihan dan pembangunan kemahiran keusahawanan sosial",
    desc: "Menyediakan pelatihan dan pembangunan kemahiran dalam bidang keusahawanan sosial kepada golongan berpendapatan rendah atau Orang Kelainan Upaya (OKU) untuk membantu mereka meningkatkan taraf hidup",
  },
  {
    title: "Pembentukan generasi muda yang cekap pengurusan teknologi",
    desc: "Mewujudkan ruang untuk membentuk generasi muda yang terampil dalam pengurusan teknologi baharu dan berfikir secara analitik, inovatif, dan kreatif",
  },
  {
    title: "Membina jalinan rangkaian profesional dan pakar industri",
    desc: " Membentuk hubungan dan kerjasama dengan golongan profesional dan pakar industri untuk membentuk kerangka kerja pembangunan komuniti dan masyarakat",
  },
  {
    title: "Mencapai Matlamat Pembangunan Mampan (SDG)",
    desc: "Membantu masyarakat untuk terlibat secara aktif dalam aktiviti-aktiviti yang berteraskan ke arah mencapai Matlamat Pembangunan Mampan (SDG)",
  },
  {
    title: "Hubungan dan kerjasama dengan pihak yang berkongsi matlamat",
    desc: "Menjalinkan hubungan dan kerjasama dengan pihak yang memiliki aspirasi dan matlamat yang sama dalam usaha memajukan kepentingan masyarakat dan negara",
  },
];

const SlickArrow = ({ currentSlide, slideCount, children, ...props }) => (
  <button {...props}>{children}</button>
);

const Objective = () => {
  const settings = {
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    adaptiveHeight: true,
    nextArrow: (
      <SlickArrow>
        <Icon name="arrow-next" size="14" />
      </SlickArrow>
    ),
    prevArrow: (
      <SlickArrow>
        <Icon name="arrow-prev" size="14" />
      </SlickArrow>
    ),
  };

  const [visibleModalBid, setVisibleModalBid] = useState(false);

  return (
    <>
      <div className={cn("section", styles.section)}>
        <div className={cn("container", styles.container)}>
          <div className={styles.head}>
            <div className={styles.stage}>
              Pertubuhan Dana Kebajikan Bertindak Altruis
            </div>
            <h3 className={cn("h3", styles.title)}>Matlamat dan Objektif</h3>
            <div className={styles.desc}>
              Bersikap lebih prihatin terhadap kebahagiaan dan kebajikan orang
              lain
            </div>
          </div>
          <div className={styles.wrapper}>
            <Slider className="creative-slider" {...settings}>
              {items.map((x, index) => (
                <div className={styles.slide} key={index}>
                  <div className={styles.row}>
                    <div className={styles.details}>
                      <h3>{x.title}</h3>
                      <p>{x.desc}</p>
                    </div>
                    <div className={styles.wrap}>
                      <Image
                        className={styles.pic}
                        src="/images/content/target.png"
                        srcDark="/images/content/target.png"
                        alt="hero"
                        width={64}
                      />
                    </div>
                  </div>
                </div>
              ))}
            </Slider>
          </div>
        </div>
      </div>
      <Modal
        visible={visibleModalBid}
        onClose={() => setVisibleModalBid(false)}
      >
        <Connect />
      </Modal>
    </>
  );
};

export default Objective;
